<template>
    <div>
        <div>
            <!-- Filters -->
            <artists-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter" :role-options="roleOptions" :plan-options="planOptions" :status-options="statusOptions" @filter="fetchAdminLogList" />

            <b-card no-body class="mb-0">
                <div class="m-2">
                    <b-row>
                        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                            <label>Show</label>
                            <b-form-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @change="changeAdminRoles({ page: 1, itemsPerPage: $event })" />
                            <label>entries</label>
                        </b-col>
                    </b-row>
                </div>

                <b-table ref="refUserListTable" class="position-relative pb-3" :items="adminLogs" responsive :fields="adminLogTableColumns" :per-page="perPage" primary-key="id" show-empty empty-text="No matching records found">
                    <template #cell(admin)="data">
                        <b-media class="align-items-center">
                            <template #aside>
                                <b-avatar size="32" :src="data.item.admin.thumbnail" :text="avatarText(data.item.admin.name)" :to="{ name: 'apps-wallet-admin-log-detail', params: { id: data.item.id } }" />
                            </template>
                            <b-link :to="{ name: 'apps-wallet-admin-log-detail', params: { id: data.item.id } }" class="font-weight-bold d-block text-nowrap">
                                {{ data.item.admin.name }}
                            </b-link>
                        </b-media>
                    </template>
                    <template #cell(user)="data">
                        <b-media v-if="data.item.user" class="align-items-center">
                            <template #aside>
                                <b-avatar size="32" :src="data.item.user.thumbnail" :text="avatarText(data.item.user.name)" />
                            </template>
                            <span class="font-weight-bolder d-block text-nowrap">
                                {{ data.item.user.name }}
                            </span>
                        </b-media>
                        <small class="text-secondary" v-else> NULL </small>
                    </template>
                    <template #cell(log_name)="data">
                        <b-badge variant="primary" class="badge-glow"> {{ data.item.log_name }} </b-badge>
                    </template>
                    <template #cell(log_type)="data">
                        <b-badge variant="light-primary" v-if="data.item.log_type == 'payment'"> {{ data.item.log_type }} </b-badge>
                        <b-badge variant="light-danger" v-else-if="data.item.log_type == 'currency'"> {{ data.item.log_type }} </b-badge>
                        <b-badge variant="light-warning" v-else-if="data.item.log_type == 'transaction'"> {{ data.item.log_type }} </b-badge>
                        <b-badge variant="light-info" v-else="data.item.log_type == 'wallet'"> {{ data.item.log_type }} </b-badge>
                    </template>
                    <template #cell(log_time)="data">
                        <b-row>
                            <b-badge variant="light-secondary"> {{ data.item.log_time.date }} </b-badge>
                        </b-row>
                        <b-row class="mt-25">
                            <b-badge variant="light-secondary">
                                {{ data.item.log_time.time }}
                            </b-badge>
                        </b-row>
                        <b-row class="mt-25">
                            <b-badge variant="light-secondary">
                                {{ data.item.log_time.timezone }}
                            </b-badge>
                        </b-row>
                    </template>
                    <template #cell(detail)="data">
                        <b-button variant="relief-info" :to="{ name: 'apps-wallet-admin-log-detail', params: { id: data.item.id } }"> Details </b-button>
                    </template>
                </b-table>
                <div class="mx-2 mb-2">
                    <b-row>
                        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                            <span class="text-muted">Showing {{ pagination.pageStart }} to {{ pagination.pageStop }} of {{ pagination.itemsLength }} entries</span>
                        </b-col>
                        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                            <b-pagination v-model="currentPage" :total-rows="pagination.itemsLength" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="changeAdminRoles({ page: $event, itemsPerPage: perPage })">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BFormSelect, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination } from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import ArtistRepository from "@/abstraction/repository/artistRepository";
import ArtistsListFilters from "./ArtistsListFilters";
import useWalletList from "./useWalletList";

const artistRepository = new ArtistRepository();

export default {
    components: {
        ArtistsListFilters,

        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormSelect,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
    },
    setup() {
        const USER_APP_STORE_MODULE_NAME = "app-user";
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        });

        const roleOptions = [
            { label: "Admin", value: "admin" },
            { label: "Author", value: "author" },
            { label: "Editor", value: "editor" },
            { label: "Maintainer", value: "maintainer" },
            { label: "Subscriber", value: "subscriber" },
        ];

        const planOptions = [
            { label: "Basic", value: "basic" },
            { label: "Company", value: "company" },
            { label: "Enterprise", value: "enterprise" },
            { label: "Team", value: "team" },
        ];

        const statusOptions = [
            { label: "Pending", value: "pending" },
            { label: "Active", value: "active" },
            { label: "Inactive", value: "inactive" },
        ];

        const {
            fetchAdminLogList,
            adminLogTableColumns,
            perPage,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            adminLogs,
            pagination,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        } = useWalletList();

        onMounted(async () => {
            await fetchAdminLogList().then((res) => {});
        });
        const changeAdminRoles = async (paginate) => {
            await fetchAdminLogList(paginate);
            await window.scrollTo(0, 0);
        };
        return {
            changeAdminRoles,
            adminLogs,
            pagination,
            fetchAdminLogList,
            adminLogTableColumns,
            perPage,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,

            // Filter
            avatarText,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,

            roleOptions,
            planOptions,
            statusOptions,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        };
    },
    methods: {
        async deleteArtist(artistId) {
            await artistRepository.delete(artistId);
            const index = this.adminLogs.findIndex((x) => x.id === artistId);
            this.$delete(this.adminLogs, index);
        },
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
