<template>
    <b-card no-body>
        <b-card-header class="pb-50">
            <h5>Filters</h5>
        </b-card-header>
        <b-card-body>
            <b-row>
                <!-- <b-col cols="12" md="4" class="mb-md-1 mb-2">
                    <label>Name</label>
                    <b-form-input id="name" v-model="form.name.val" class="d-inline-block mr-1" />
                </b-col>
                <b-col cols="12" md="4" class="mb-md-1 mb-2">
                    <label>Arabic Name</label>
                    <b-form-input id="name" v-model="form.name_ar.val" class="d-inline-block mr-1" />
                </b-col>
                <b-col cols="12" md="4" class="mb-md-1 mb-2">
                    <label>User Name</label>
                    <b-form-input id="name" v-model="form.user_name.val" class="d-inline-block mr-1" />
                </b-col>
                <b-col cols="12" md="4" class="mb-md-1 mb-2">
                    <label>Email</label>
                    <b-form-input id="name" v-model="form.email.val" class="d-inline-block mr-1" />
                </b-col> -->
                <b-col cols="12" md="4" class="mb-md-1 mb-2">
                    <label>Log type</label>
                    <v-select v-model="form.log_type.val" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val" :options="logTypeOptions" label="name" :clearable="true" input-id="country" @option:selected="changeCountry" />
                </b-col>
                <b-col cols="12" md="4" class="mb-md-1 mb-2">
                    <label>Log name</label>
                    <v-select v-model="form.log_name.val" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val" :options="logNameOptions" :clearable="true" label="name" input-id="city" />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="4" class="mb-md-0 mb-2">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="button" variant="danger" class="mt-1" @click="filterArtist"> Filter </b-button>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import useWalletList from "./useWalletList";
import { avatarText } from "@core/utils/filter";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";
import CityRepository from "@/abstraction/repository/cityRepository";
const cityRepository = new CityRepository();

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BFormInput,
        vSelect,
        BButton,
    },
    directives: {
        Ripple,
    },
    computed: {
        ...mapGetters("country", ["getCountries"]),
    },
    props: {
        roleFilter: {
            type: [String, null],
            default: null,
        },
        planFilter: {
            type: [String, null],
            default: null,
        },
        statusFilter: {
            type: [String, null],
            default: null,
        },
        roleOptions: {
            type: Array,
            required: true,
        },
        planOptions: {
            type: Array,
            required: true,
        },
        statusOptions: {
            type: Array,
            required: true,
        },
    },
    setup() {
        const isLoading = ref();
        const blankForm = {
            name: {
                type: "like",
                val: null,
            },
            name_ar: {
                type: "like",
                val: null,
            },
            user_name: {
                type: "like",
                val: null,
            },
            email: {
                type: "like",
                val: null,
            },
            log_type: {
                type: "like",
                val: null,
            },
            log_name: {
                type: "like",
                val: null,
            },
        };
        const logTypeOptions = ref(["payment", "wallet", "transaction", "currency"]);
        const logNameOptions = ref(["view", "edit", "bonus", "delete", "store"]);
        const cities = ref([]);
        const form = ref(blankForm);
        const {
            fetchArtists,
            tableColumns,
            perPage,
            pagination,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            artists,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,
        } = useWalletList();

        return {
            form,
            isLoading,
            cities,
            // Sidebar
            fetchArtists,
            pagination,
            tableColumns,
            perPage,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            artists,
            avatarText,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,
            logTypeOptions,
            logNameOptions,
        };
    },
    methods: {
        ...mapActions("country", ["loadCountries"]),
        async changeCountry() {
            if (this.form.country_id.val) {
                const resoruce = await cityRepository.index(this.form.country_id.val);
                this.cities = resoruce.data;
            }
        },
        filterArtist() {
            this.$emit("filter", { page: 1, itemsPerPage: this.perPage }, this.form);
        },
    },
    created() {
        this.loadCountries();
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
